import { useFormRendererInfo } from '../../contexts/FormRendererContext';
import { FCWithChildren } from '../../types/FCWithChildren';

const PublicFormBox: FCWithChildren<{ className?: string; zIndex?: number }> = (props) => {
  const { children, className, zIndex = 0 } = props;
  const { rootContext, clientFormId } = useFormRendererInfo();
  return (
    <div
      style={{ zIndex: zIndex, wordBreak: 'break-word', overflowWrap: 'break-word' }}
      className={`relative my-8 rounded-md bg-white px-4 ${!rootContext || rootContext?.clientFormId === clientFormId ? 'py-6 shadow-[0px_20px_90px_0px_rgba(0,0,0,0.1)] sm:px-6 md:px-8 lg:px-10 xl:px-12 2xl:px-16' : 'py-0 sm:px-6 md:px-8 lg:px-8 xl:px-8 2xl:px-8'}  ${className || ''}`}
    >
      {children}
    </div>
  );
};

export default PublicFormBox;
